// import React, { Component } from "react";
// import * as THREE from "three";
// import { TWEEN } from "three/addons/libs/tween.module.min.js";
// import { OrbitControls } from "three/addons/controls/OrbitControls.js";
// import { FontLoader } from "three/addons/loaders/FontLoader.js";
// import font from "./fonts/helvetiker_regular.typeface.json";
// import { NewApp } from "./NewApp";
// import { createRoot } from "react-dom/client";

// const vertices = [
//   new THREE.Vector3(0, 0, 0), // point C
//   new THREE.Vector3(0, 5, -1.5),
//   new THREE.Vector3(5, 5, 0), // point A
//   new THREE.Vector3(9, 9, 0),
//   new THREE.Vector3(5, 9, 2),
//   new THREE.Vector3(7, 13, 0),
//   new THREE.Vector3(3, 13, 0),
//   new THREE.Vector3(0, 11, 0),
//   new THREE.Vector3(5, 9, -2),
//   new THREE.Vector3(0, 8, -3),
//   new THREE.Vector3(0, 8, 3),
//   new THREE.Vector3(0, 5, 1.5), // point B
//   new THREE.Vector3(-9, 9, 0),
//   new THREE.Vector3(-5, 5, 0),
//   new THREE.Vector3(-5, 9, -2),
//   new THREE.Vector3(-5, 9, 2),
//   new THREE.Vector3(-7, 13, 0),
//   new THREE.Vector3(-3, 13, 0),
// ];
// //prettier-ignore
// const trianglesIndexes = [
//   // face 1
//   2, 11, 0, // This represents the 3 points A,B,C which compose the first triangle
//   2, 3, 4,
//   5, 4, 3,
//   4, 5, 6,
//   4, 6, 7,
//   4, 7, 10,
//   4, 10, 11,
//   4, 11, 2,
//   0, 11, 13,
//   12, 13, 15,
//   12, 15, 16,
//   16, 15, 17,
//   17, 15, 7,
//   7, 15, 10,
//   11, 10, 15,
//   13, 11, 15,
//   // face 2
//   0, 1, 2,
//   1, 9, 2,
//   9, 8, 2,
//   5, 3, 8,
//   8, 3, 2,
//   6, 5, 8,
//   7, 6, 8,
//   9, 7, 8,
//   14, 17, 7,
//   14, 7, 9,
//   14, 9, 1,
//   9, 1, 13,
//   1, 0, 13,
//   14, 1, 13,
//   16, 14, 12,
//   16, 17, 14,
//   12, 14, 13
// ]

// function createHeartMesh(coordinatesList, trianglesIndexes) {
//   const geo = new THREE.BufferGeometry();
//   const vertices = coordinatesList.map((c) => new THREE.Vector3(c.x, c.y, c.z));
//   const verticesArray = new Float32Array(vertices.length * 3);
//   vertices.forEach((v, i) => {
//     verticesArray[i * 3] = v.x;
//     verticesArray[i * 3 + 1] = v.y;
//     verticesArray[i * 3 + 2] = v.z;
//   });
//   geo.setAttribute("position", new THREE.BufferAttribute(verticesArray, 3));
//   const triangles = new Uint16Array(trianglesIndexes);
//   geo.setIndex(new THREE.BufferAttribute(triangles, 1));
//   const Hmaterial = new THREE.MeshBasicMaterial({ color: 0xad0c00 });
//   const mesh = new THREE.Mesh(geo, Hmaterial);
//   return { mesh, geo, Hmaterial };
// }

// function addWireFrame(mesh) {
//   const wireframe = new THREE.WireframeGeometry(mesh.geometry);
//   const lineMat = new THREE.LineBasicMaterial({
//     color: 0x000000,
//     linewidth: 1,
//   });
//   const line = new THREE.LineSegments(wireframe, lineMat);
//   mesh.add(line);
// }
// const beatingIncrement = 0.008;
// let scaleThreshold = false;
// function beatingAnimation(mesh) {
//   // while the scale value is below the max,
//   // and the threshold is not reached, we increase it
//   if (mesh.scale.x < 3.4 && !scaleThreshold) {
//     mesh.scale.x += beatingIncrement * 2;
//     // mesh.scale.y += beatingIncrement * 2
//     mesh.scale.z += beatingIncrement * 2;
//     // When max value is reached, the flag can be switched
//     if (mesh.scale.x >= 3.4) scaleThreshold = true;
//   } else if (scaleThreshold) {
//     mesh.scale.x -= beatingIncrement * 2;
//     // mesh.scale.y -= beatingIncrement
//     mesh.scale.z -= beatingIncrement * 2;
//     // The mesh got back to its initial state
//     // we can switch back the flag and go through the increasing path next time
//     if (mesh.scale.x <= 3) {
//       scaleThreshold = false;
//     }
//   }
// }

// class Experiment extends Component {
//   mount;
//   componentDidMount() {
//     const renderer = new THREE.WebGLRenderer();
//     renderer.setPixelRatio(window.devicePixelRatio);

//     const camera = new THREE.PerspectiveCamera(
//       30,
//       window.innerWidth / window.innerHeight,
//       1,
//       10000
//     );

//     const controls = new OrbitControls(camera, renderer.domElement);

//     const scene = new THREE.Scene();

//     const matFloor = new THREE.MeshPhongMaterial();
//     const matBox = new THREE.MeshPhongMaterial({ color: 0xaaaaaa });
//     const { geo, mesh, Hmaterial } = createHeartMesh(
//       vertices,
//       trianglesIndexes
//     );

//     const geoFloor = new THREE.PlaneGeometry(500, 500);
//     const geoBox = new THREE.BoxGeometry(3, 1, 2);

//     const mshFloor = new THREE.Mesh(geoFloor, matFloor);
//     mshFloor.rotation.x = -Math.PI * 0.5;
//     const mshBox = new THREE.Mesh(geoBox, matBox);

//     const ambient = new THREE.AmbientLight("#000000");
//     // const ambient = new THREE.AmbientLight(0x404040);

//     const spotLight1 = createSpotlight("#FC8ADC");
//     const spotLight2 = createSpotlight("#FC8AA3");
//     const spotLight3 = createSpotlight("#E28AFC");
//     const spotLight4 = createSpotlight("#E15156");

//     let lightHelper1, lightHelper2, lightHelper3;
//     var text;

//     function init() {
//       renderer.shadowMap.enabled = true;
//       renderer.shadowMap.type = THREE.PCFSoftShadowMap;
//       renderer.outputEncoding = THREE.sRGBEncoding;

//       camera.position.set(46, 30, -21);

//       spotLight1.position.set(15, 200, 45);
//       spotLight2.position.set(0, 200, 35);
//       spotLight3.position.set(-15, 200, 45);
//       spotLight4.position.set(0, 200, 300);

//       lightHelper1 = new THREE.SpotLightHelper(spotLight1);
//       lightHelper2 = new THREE.SpotLightHelper(spotLight2);
//       lightHelper3 = new THREE.SpotLightHelper(spotLight3);

//       matFloor.color.set("#F8BFAB");

//       mshFloor.receiveShadow = true;
//       mshFloor.position.set(0, -0.05, 0);

//       mshBox.castShadow = true;
//       mshBox.receiveShadow = true;
//       mshBox.position.set(0, 5, 0);

//       mesh.castShadow = true;
//       mesh.receiveShadow = true;
//       mesh.position.set(0, 5, 0);
//       // mesh.scale.x = 3;
//       // mesh.scale.y = 3;
//       // mesh.scale.z = 3;
//       var loader = new FontLoader().parse(font);
//       console.log(loader);

//       const color = "#E15156";

//       const matDark = new THREE.LineBasicMaterial({
//         color: color,
//         side: THREE.DoubleSide,
//       });

//       const matLite = new THREE.MeshBasicMaterial({
//         color: color,
//         transparent: true,
//         opacity: 0.4,
//         side: THREE.DoubleSide,
//       });

//       const message = "Sutanshu\nJosephine";

//       const shapes = loader.generateShapes(message, 100);

//       const geometry = new THREE.ShapeGeometry(shapes);

//       geometry.computeBoundingBox();

//       const xMid =
//         -0.5 * (geometry.boundingBox.max.x - geometry.boundingBox.min.x);

//       geometry.translate(xMid, 0, 0);

//       //   // make shape ( N.B. edge view not visible )

//       text = new THREE.Mesh(geometry, matLite);
//       text.castShadow = true;
//       text.receiveShadow = true;
//       text.rotation.x = -Math.PI * 0.5;
//       text.position.set(0, 100, -50);

//       scene.add(text);

//       //   // make line shape ( N.B. edge view remains visible )

//       const holeShapes = [];

//       for (let i = 0; i < shapes.length; i++) {
//         const shape = shapes[i];

//         if (shape.holes && shape.holes.length > 0) {
//           for (let j = 0; j < shape.holes.length; j++) {
//             const hole = shape.holes[j];
//             holeShapes.push(hole);
//           }
//         }
//       }

//       shapes.push.apply(shapes, holeShapes);

//       const lineText = new THREE.Object3D();

//       for (let i = 0; i < shapes.length; i++) {
//         const shape = shapes[i];

//         const points = shape.getPoints();
//         const geometry = new THREE.BufferGeometry().setFromPoints(points);

//         geometry.translate(xMid, 0, 0);

//         const lineMesh = new THREE.Line(geometry, matDark);
//         lineText.add(lineMesh);
//       }
//       lineText.rotation.x = -Math.PI * 0.5;
//       lineText.position.set(0, 0, -50);
//       lineText.castShadow = true;
//       lineText.receiveShadow = true;

//       scene.add(lineText);
//       scene.add(mshFloor);
//       // scene.add(mshBox);
//       scene.add(mesh);
//       addWireFrame(mesh);
//       scene.add(ambient);
//       scene.add(spotLight1, spotLight2, spotLight3);

//       // scene.add(lightHelper1, lightHelper2, lightHelper3);
//       document.body.appendChild(renderer.domElement);

//       onWindowResize();
//       window.addEventListener("resize", onWindowResize);

//       controls.target.set(0, 7, 0);
//       controls.maxPolarAngle = Math.PI / 2;
//       controls.update();
//     }

//     function createSpotlight(color) {
//       const newObj = new THREE.SpotLight(color, 1);

//       newObj.castShadow = true;
//       newObj.angle = 0.5;
//       newObj.penumbra = 0.2;
//       newObj.decay = 2;
//       newObj.distance = 500;

//       return newObj;
//     }

//     function onWindowResize() {
//       camera.aspect = window.innerWidth / window.innerHeight;
//       camera.updateProjectionMatrix();
//       renderer.setSize(window.innerWidth, window.innerHeight);
//     }

//     function tween(light) {
//       new TWEEN.Tween(light)
//         .to(
//           {
//             angle: Math.random() * 0.8 + 0.3,
//             penumbra: 1,
//           },
//           Math.random() * 3000 + 3000
//         )
//         .easing(TWEEN.Easing.Quadratic.Out)
//         .start();

//       new TWEEN.Tween(light.position)
//         .to(
//           {
//             x: Math.random() * 30 - 15,
//             y: Math.random() * 200 + 15,
//             z: Math.random() * 30 - 15,
//           },
//           Math.random() * 3000 + 3000
//         )
//         .easing(TWEEN.Easing.Quadratic.Out)
//         .start();
//     }

//     function animate() {
//       tween(spotLight1);
//       tween(spotLight2);
//       tween(spotLight3);

//       setTimeout(animate, 4000);
//     }
//     function render() {
//       TWEEN.update();
//       mesh.rotation.y += 0.01;

//       if (lightHelper1) lightHelper1.update();
//       if (lightHelper2) lightHelper2.update();
//       if (lightHelper3) lightHelper3.update();
//       // beatingAnimation(mesh);

//       renderer.render(scene, camera);

//       requestAnimationFrame(render);
//     }
//     init();
//     render();
//     animate();
//   }

//   render() {
//     return <div ref={(ref) => (this.mount = ref)} id="Heart" />;
//   }
// }

// const rootElement = document.getElementById("root");
// createRoot(rootElement).render(
//   <>
//     {/* <Experiment /> */}
//     <NewApp />
//   </>,

//   rootElement
// );
import { createRoot } from "react-dom/client";
import "./styles.css";
import { NewApp } from "./NewApp";
import { Leva } from "leva";

createRoot(document.getElementById("root")).render(
  <>
    <NewApp />
    <Leva collapsed />
  </>
);
